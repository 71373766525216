<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-btn color="primary" @click.prevent="addText">Insert Text</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <vue-editor
            ref="editor"
            @ready="onEditorReady($event)"
            v-model="content"
            @focus="onFocus($event)"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { defineComponent, reactive, ref, toRefs } from "vue";
  import { VueEditor } from "vue2-editor";

  export default defineComponent({
    components: {
      VueEditor,
    },

    setup() {
      const data = reactive({
        content: "",
        position: 0,
      });
      const editor = ref(null);

      const onFocus = (quill) => (editor.value = quill);

      const onEditorReady = (quill) => (editor.value = quill);

      const { position } = toRefs(data);

      const addText = () => {
        position.value = editor.value?.getSelection();
        editor.value?.insertText(position.value?.index ?? 0, "{HELLO WORLD}");
      };

      return {
        ...toRefs(data),
        onFocus,
        addText,
        onEditorReady,
      };
    },
  });
</script>
